import Vue from 'vue';

const config = {
  host: {
    image: 'https://s3.ap-northeast-2.amazonaws.com/bifi.finance/',
    etherscan: {
      transaction: 'https://{prefix}etherscan.io/tx/{transactionHash}',
      address: 'https://{prefix}etherscan.io/address/{address}',
    },
    bscscan: {
      transaction: 'https://{prefix}bscscan.com/tx/{transactionHash}',
      address: 'https://{prefix}bscscan.com/address/{address}',
    },
    avalanchescan: {
      transaction: 'https://cchain.explorer.avax.network/tx/{transactionHash}',
      address: 'https://cchain.explorer.avax.network/address/{address}',
    },
    // TODO avalanche testnet
    avalanchetestscan: {
      transaction: 'https://avascan.info/',
      address: 'https://avascan.info/',
    },
    klaytnscan: {
      transaction: 'https://klaytnscope.com/tx/{transactionHash}',
      address: 'https://klaytnscope.com/account/{address}',
    },
    klaytntestscan: {
      transaction: 'https://baobab.klaytnscope.com/tx/{transactionHash}',
      address: 'https://baobab.klaytnscope.com/account/{address}',
    },
    bifrostscan: {
      transaction:
        'https://explorer.mainnet.thebifrost.io/tx/{transactionHash}',
      address: 'https://explorer.mainnet.thebifrost.io/address/{address}',
    },
    bifrosttestscan: {
      transaction:
        'https://explorer.testnet.thebifrost.io/tx/{transactionHash}',
      address: 'https://explorer.testnet.thebifrost.io/address/{address}',
    },
    btcWallet: 'https://address-server.thebifrost.io',
    relayer: 'https://tracker.thebifrost.io',
  },
  path: {
    btcWallet: {
      getAddresses: '/get_btc_addrs',
      setAddress: '/set_refund_addr',
    },
    relayer: {
      getInflow: '/inflow/{publicKeyHash}',
      getOutflow: '/outflow/{publicKeyHash}/{action}',
    },
  },
  relayer: {
    transactionStatuses: [
      'cancelled',
      'awaiting',
      'rejected',
      'processing',
      'completed',
    ],
  },
  link: {
    twitter: {
      bifrost: 'https://twitter.com/bifrostio',
      bifi: 'https://twitter.com/bifi_lending',
    },
    youtube: 'https://www.youtube.com/channel/UCYm4MGAGGGFUdykEr3JlHoA',
    facebook: 'https://www.facebook.com/theBifrost.io',
    medium: 'https://medium.com/bifrost',
    bifrost: 'https://theBifrost.io',
    bfc: 'https://bfcbot.com',
    xbifi: 'https://x.bifi.finance',
    gitHub: 'https://github.com/bifrost-platform',
    wallet: {
      metamask: {
        default: 'https://metamask.app.link/dapp/app.bifi.finance',
      },
      coinbase: {
        default: 'https://coinbase-wallet.onelink.me/q5Sx/fdb9b250',
      },
      biport: {
        default:
          'https://chrome.google.com/webstore/detail/mapbhaebnddapnmifbbkgeedkeplgjmf',
      },
    },
    uniswap: {
      add: 'https://app.uniswap.org/#/add/v2/ETH/{address}',
      swap:
        'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency={address}',
    },
    sushiswap: {
      add: 'https://app.sushi.com/add/ETH/{address}',
      swap:
        'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency={address}',
    },
    governance: 'https://www.withtally.com/governance/bifi',
    gbifiSwap: 'https://gov.bifi.finance',
    helpcenter: 'https://pi-lab.atlassian.net/servicedesk/customer/portal/7',
    productRequest:
      'https://discord.com/channels/898031660396863529/919867913857564673',
  },
  infura: {
    projectID: 'af006865e97d4e93afbf67b941291847',
  },
  disabledRouters: [
    {
      id: 'bsc',
      routers: ['pool', 'stake'],
    },
    {
      id: 'avalanche',
      routers: ['pool', 'stake'],
    },
    {
      id: 'klaytn',
      routers: ['pool', 'stake'],
    },
    {
      id: 'bifrost',
      routers: ['pool', 'stake'],
    },
  ],
  tokens: [
    {
      id: 'ether',
      title: 'ETH',
      symbol: 'ETH',
      unit: 'ETH',
      replaceString: '{} ETH',
      image: 'eth.svg',
    },
    {
      id: 'eth',
      title: 'ETH',
      symbol: 'ETH',
      unit: 'ETH',
      replaceString: '{} ETH',
      image: 'eth.svg',
    },
    {
      id: 'usdt',
      title: 'USDT',
      symbol: 'USDT',
      unit: 'USDT',
      replaceString: '{} USDT',
      image: 'usdt.svg',
    },
    {
      id: 'dai',
      title: 'DAI',
      symbol: 'DAI',
      unit: 'DAI',
      replaceString: '{} DAI',
      image: 'dai.svg',
    },
    {
      id: 'link',
      title: 'LINK',
      symbol: 'LINK',
      unit: 'LINK',
      replaceString: '{} LINK',
      image: 'link.svg',
    },
    {
      id: 'usdc',
      title: 'USDC',
      symbol: 'USDC',
      unit: 'USDC',
      replaceString: '{} USDC',
      image: 'usdc.svg',
    },
    {
      id: 'bnb',
      title: 'BNB',
      symbol: 'BNB',
      unit: 'BNB',
      replaceString: '{} BNB',
      image: 'bnb.svg',
    },
    {
      id: 'avax',
      title: 'AVAX',
      symbol: 'AVAX',
      unit: 'AVAX',
      replaceString: '{} AVAX',
      image: 'avax.svg',
    },
    {
      id: 'weth.e',
      title: 'WETH.e',
      symbol: 'ETH.e',
      unit: 'ETH.e',
      replaceString: '{} ETH.e',
      image: 'eth.svg',
    },
    {
      id: 'usdt.e',
      title: 'USDT.e',
      symbol: 'USDT.e',
      unit: 'USDT.e',
      replaceString: '{} USDT.e',
      image: 'usdt.svg',
    },
    {
      id: 'usdc.e',
      title: 'USDC.e',
      symbol: 'USDC.e',
      unit: 'USDC.e',
      replaceString: '{} USDC.e',
      image: 'usdc.svg',
    },
    {
      id: 'dai.e',
      title: 'DAI.e',
      symbol: 'DAI.e',
      unit: 'DAI.e',
      replaceString: '{} DAI.e',
      image: 'dai.svg',
    },
    {
      id: 'wbtc.e',
      title: 'WBTC.e',
      symbol: 'WBTC.e',
      unit: 'WBTC.e',
      replaceString: '{} WBTC.e',
      image: 'wbtc.svg',
    },
    {
      id: 'btcb',
      title: 'BTCB',
      symbol: 'BTCB',
      unit: 'BTCB',
      replaceString: '{} BTCB',
      image: 'btcb.svg',
    },
    {
      id: 'wbtc',
      title: 'WBTC',
      symbol: 'WBTC',
      unit: 'WBTC',
      replaceString: '{} WBTC',
      image: 'wbtc.svg',
    },
    {
      id: 'busd',
      title: 'BUSD',
      symbol: 'BUSD',
      unit: 'BUSD',
      replaceString: '{} BUSD',
      image: 'busd.svg',
    },
    {
      id: 'xrp',
      title: 'XRP',
      symbol: 'XRP',
      unit: 'XRP',
      replaceString: '{} XRP',
      image: 'xrp.svg',
    },
    {
      id: 'oether',
      title: 'oETH',
      symbol: 'oETH',
      unit: 'oETH',
      replaceString: '{} oETH',
      image: 'oeth.svg',
    },
    {
      id: 'ousdt',
      title: 'oUSDT',
      symbol: 'oUSDT',
      unit: 'oUSDT',
      replaceString: '{} oUSDT',
      image: 'ousdt.svg',
    },
    {
      id: 'kdai',
      title: 'kDAI',
      symbol: 'kDAI',
      unit: 'kDAI',
      replaceString: '{} kDAI',
      image: 'kdai.png',
    },
    {
      id: 'ousdc',
      title: 'oUSDC',
      symbol: 'oUSDC',
      unit: 'oUSDC',
      replaceString: '{} oUSDC',
      image: 'ousdc.png',
    },
    {
      id: 'owbtc',
      title: 'oWBTC',
      symbol: 'oWBTC',
      unit: 'oWBTC',
      replaceString: '{} oWBTC',
      image: 'owbtc.svg',
    },
    {
      id: 'oxrp',
      title: 'oXRP',
      symbol: 'oXRP',
      unit: 'oXRP',
      replaceString: '{} oXRP',
      image: 'oxrp.svg',
    },
    {
      id: 'btc',
      title: 'BTC',
      symbol: 'BTC',
      unit: 'BTC',
      replaceString: '{} BTC',
      image: 'btc.svg',
    },
    {
      id: 'klay',
      title: 'KLAY',
      symbol: 'KLAY',
      unit: 'KLAY',
      replaceString: '{} KLAY',
      image: 'klay.svg',
    },
    {
      id: 'matic',
      title: 'MATIC',
      symbol: 'MATIC',
      unit: 'MATIC',
      replaceString: '{} MATIC',
      image: 'matic.svg',
    },
    {
      id: 'dot',
      comingSoon: true,
      disabled: true,
      title: 'DOT',
      symbol: 'DOT',
      unit: 'DOT',
      replaceString: '{} DOT',
      image: 'dot.svg',
    },
    {
      id: 'bfc',
      srd: true,
      title: 'BFC',
      symbol: 'BFC',
      unit: 'BFC',
      replaceString: '{} BFC',
      image: 'bfc.png',
      disabledBorrow: true,
    },
    {
      id: 'bifi',
      srd: true,
      title: 'BiFi',
      symbol: 'BiFi',
      unit: 'BiFi',
      replaceString: '{} BiFi',
      image: 'bifi.png',
    },
    {
      id: 'uniswap.bfceth',
      srdTokenID: 'bfceth',
      srd: true,
      title: 'BFC-ETH',
      symbol: 'UNI-V2',
      unit: 'UNI-V2',
      replaceString: '{} UNI-V2',
      image: 'bfceth.png',
      pairTokens: ['bfc', 'ether'],
    },
    {
      id: 'uniswap.bifieth',
      srdTokenID: 'bifieth',
      srd: true,
      title: 'BiFi-ETH',
      symbol: 'UNI-V2',
      unit: 'UNI-V2',
      replaceString: '{} UNI-V2',
      image: 'bifieth.png',
      pairTokens: ['bifi', 'ether'],
    },
    {
      id: 'sushiswap.bfceth',
      srdTokenID: 'bfceth',
      srd: true,
      title: 'BFC-ETH',
      symbol: 'SLP',
      unit: 'SLP',
      replaceString: '{} SLP',
      image: 'bfceth.png',
      pairTokens: ['bfc', 'ether'],
    },
    {
      id: 'sushiswap.bifieth',
      srdTokenID: 'bifieth',
      srd: true,
      title: 'BiFi-ETH',
      symbol: 'SLP',
      unit: 'SLP',
      replaceString: '{} SLP',
      image: 'bifieth.png',
      pairTokens: ['bifi', 'ether'],
    },
  ],
  typeTokens: [
    'usdt',
    'usdc',
    'usdk',
    'erc20',
    'kct7',
    'dai',
    'xrp',
    'link',
    'weth.e',
    'usdt.e',
    'usdc.e',
    'dai.e',
    'wbtc.e',
    'oether',
    'ousdt',
    'ousdc',
    'kdai',
    'kwbtc',
    'kxrp',
    'owbtc',
    'oxrp',
    'btc',
    'bfceth',
    'bifieth',
    'uniswap.bfceth',
    'uniswap.bifieth',
    'sushiswap.bfceth',
    'sushiswap.bifieth',
    'bifi',
    'dot',
    'btcb',
    'wbtc',
    'busd',
    'weth',
  ],
  dexTokens: [
    {
      id: 'bfceth',
      to: 'bfcEth',
      title: 'BFC-ETH',
      image: 'bfceth.png',
    },
    {
      id: 'bifieth',
      to: 'bifiEth',
      title: 'BiFi-ETH',
      image: 'bifieth.png',
    },
  ],
  signature: {
    deposit: 'deposit(uint256,bool)',
    withdraw: 'withdraw(uint256,bool)',
    borrow: 'borrow(uint256,bool)',
    repay: 'repay(uint256,bool)',
    srd: {
      deposit: 'deposit(uint256)',
      withdraw: 'withdraw(uint256)',
      claim: 'rewardClaim()',
    },
    si: {
      claim: 'rewardClaimAll(address)',
    },
    btc: {
      register: 'registerUser(uint32,address[],bytes32[])',
      registerRefund: 'registerRefund(uint32,address)',
      handlerViewProxy: 'handlerViewProxy(bytes)',
      withdraw: 'reqExternalWithdraw(uint256,bool)',
      borrow: 'reqExternalborrow(uint256,bool)',
    },
  },
  abi: {
    btc: {
      handlerViewProxy: {
        name: 'handlerViewProxy',
        type: 'function',
        inputs: [
          {
            type: 'bytes',
            name: 'myNumber',
          },
        ],
      },
    },
  },
  languages: [
    {
      id: 'en',
      title: 'English',
    },
    {
      id: 'ko',
      title: '한국어',
    },
    {
      id: 'ja',
      title: '日本語',
    },
    {
      id: 'zh-tw',
      title: '繁體中文',
    },
    {
      id: 'zh-cn',
      title: '简体中文',
    },
    {
      id: 'fr',
      title: 'Français',
    },
    {
      id: 'es',
      title: 'Español',
    },
    {
      id: 'ru',
      title: 'Русский',
    },
    {
      id: 'uk',
      title: 'Українська',
    },
    {
      id: 'id',
      title: 'Bahasa Indonesia',
    },
    {
      id: 'vi',
      title: 'Tiếng Việt',
    },
    {
      id: 'tr',
      title: 'Türkçe',
    },
  ],
  chains: [
    {
      id: 'mainnet',
      blockExplorerUrls: ['https://etherscan.io'],
      rpcUrls: ['https://mainnet.infura.io/v3/undefined'],
    },
    {
      id: 'kovan',
      blockExplorerUrls: ['https://kovan.etherscan.io'],
      rpcUrls: ['https://kovan.infura.io/v3/undefined'],
    },
    {
      id: 'bsc',
      blockExplorerUrls: ['https://bscscan.com/'],
      chainName: 'Binance Smart Chain Mainnet',
      iconUrls: ['bnb.svg'],
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [
        'https://bsc-dataseed.binance.org/',
        'https://bsc-dataseed1.defibit.io/',
        'https://bsc-dataseed1.ninicoin.io/',
      ],
    },
    {
      id: 'bsc-test',
      blockExplorerUrls: ['https://bscscan.com/'],
      chainName: 'Binance Smart Chain Testnet',
      iconUrls: ['bnb.svg'],
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [
        'https://data-seed-prebsc-1-s1.binance.org:8545/',
        'https://data-seed-prebsc-2-s1.binance.org:8545/',
        'https://data-seed-prebsc-1-s2.binance.org:8545/',
        'https://data-seed-prebsc-2-s2.binance.org:8545/',
        'https://data-seed-prebsc-1-s3.binance.org:8545/',
        'https://data-seed-prebsc-2-s3.binance.org:8545/',
      ],
    },
    {
      id: 'avalanche',
      blockExplorerUrls: ['https://cchain.explorer.avax.network/'],
      chainName: 'Avalanche Chain Mainnet',
      iconUrls: ['avax.svg'],
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    },
    {
      id: 'avalanche-test',
      blockExplorerUrls: ['https://avascan.info/'],
      chainName: 'Avalanche Chain Testnet',
      iconUrls: ['avax.svg'],
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    },
    {
      id: 'klaytn',
      blockExplorerUrls: ['https://kaiascope.com/'],
      chainName: 'Kaia Mainnet',
      iconUrls: ['klay.svg'],
      nativeCurrency: {
        name: 'KLAY',
        symbol: 'KLAY',
        decimals: 18,
      },
      rpcUrls: ['https://public-en.node.kaia.io/'],
    },
    {
      id: 'klaytn-test',
      blockExplorerUrls: ['https://kairos.kaiascope.com/'],
      chainName: 'Kaia Testnet Kairos',
      iconUrls: ['klay.svg'],
      nativeCurrency: {
        name: 'KLAY',
        symbol: 'KLAY',
        decimals: 18,
      },
      rpcUrls: ['https://public-en.kairos.node.kaia.io/'],
    },
    {
      id: 'bifrost',
      blockExplorerUrls: ['https://explorer.mainnet.thebifrost.io/'],
      chainName: 'Bifrost Chain',
      iconUrls: ['bfc.svg'],
      nativeCurrency: {
        name: 'BFC',
        symbol: 'BFC',
        decimals: 18,
      },
      rpcUrls: ['https://public-01.mainnet.thebifrost.io/rpc'],
    },
    {
      id: 'bifrost-test',
      blockExplorerUrls: ['https://explorer.testnet.thebifrost.io/'],
      chainName: 'Bifrost Testnet',
      iconUrls: ['bfc.svg'],
      nativeCurrency: {
        name: 'BFC',
        symbol: 'BFC',
        decimals: 18,
      },
      rpcUrls: ['https://public-01.testnet.thebifrost.io/rpc'],
    },
  ],
  circuitBreakerRoutes: ['Lend', 'Reward', 'Market'],
  oracleCircuitBreakerRoutes: ['Lend', 'Reward', 'Market'],
  dexes: [
    {
      id: 'sushiswap',
      title: 'SushiSwap',
      image: 'sushiswap.svg',
      to: '/pool/sushiswap/',
    },
    {
      id: 'uniswap',
      title: 'UniSwap',
      image: 'uniswap.svg',
      to: '/pool/uniswap/',
    },
  ],
  uintMaxValue: '115792089237316195423570985008687907853269984665640564039458',
  maxActionRatio: 1.0001,
  maxActionTokenRatio: 1.1,
  metamask: {
    code: {
      headerNotFound: -32000,
      pending: -32002,
      internal: -32603,
      parameterInvalid: -32602,
      userCancel: 4001,
      timeout: -99999,
      noResponse: -88888,
      diffNetwork: -77777,
      revert: -22222,
    },
  },
  allowedNetworks: ['mainnet'],
  expectedBlock: {
    // totaly 15 mins
    cycle: 150, // 150 times
    time: 6000, // 6 sec
    // for infura cycle
    infura: 10, // 10 times = 6 * 10 = 1 min
  },
  daysPerYear: 365,
  daysPerWeek: 7,
  openBeta: false,
};

try {
  if (process.env.VUE_APP_ENABLE_KOVAN === 'true') {
    config.allowedNetworks.push('kovan');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_BSC === 'true') {
    config.allowedNetworks.push('bsc');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_AVALANCHE === 'true') {
    config.allowedNetworks.push('avalanche');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_KLAYTN === 'true') {
    config.allowedNetworks.push('klaytn');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_BIFROST === 'true') {
    config.allowedNetworks.push('bifrost');
  }
} catch (error) {
  /**/
}

try {
  if (process.env.VUE_APP_ENABLE_BSC_TEST === 'true') {
    config.allowedNetworks.push('bsc-test');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_AVALANCHE_TEST === 'true') {
    config.allowedNetworks.push('avalanche-test');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_KLAYTN_TEST === 'true') {
    config.allowedNetworks.push('klaytn-test');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_BIFROST_TEST === 'true') {
    config.allowedNetworks.push('bifrost-test');
  }
} catch (error) {
  /**/
}
try {
  if (process.env.VUE_APP_ENABLE_PILAB_FORK === 'true') {
    config.allowedNetworks.push('pilab-fork');
  }
} catch (error) {
  /**/
}

try {
  const urlParams = new URLSearchParams(window.location.search);
  const chainID = urlParams.get('chainid');

  if (
    chainID &&
    chainID !== 'undefined' &&
    config.allowedNetworks.indexOf(chainID) >= 0
  ) {
    config.allowedNetworks = [chainID];
  }
} catch (error) {
  /**/
}

try {
  config.openBeta = process.env.VUE_APP_OPEN_BETA === 'true';
} catch (error) {
  /**/
}

Vue.prototype.$config = config;

export default config;
